import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import role from '@/auth/role'
import i18n from '@/libs/i18n'
import { parseRequestError } from '@/helpers/helpers'

export default (props, { root }) => {
  const route = root.$route
  const loading = ref(false)
  const processing = ref(false)
  const educator = ref({ roles: [role.educator] })

  loading.value = true
  store.dispatch('organization/getOrganizationUser', route.params.id)
    .then(res => {
      educator.value = res.data
    })
    .catch(err => {
      window.toast.notify.danger({ title: 'Error', message: err.message, icon: 'error' })
    })
    .finally(() => {
      loading.value = false
    })

  const phoneNumberValid = ref(false)
  const {
    refFormObserver,
    getValidationState,
    resetForm,
    resetObserver,
  } = formValidation()

  const handlePhoneNumberValidation = phoneObject => {
    phoneNumberValid.value = phoneObject.valid === undefined || phoneObject.valid
  }

  const onSubmit = () => {
    processing.value = true
    store.dispatch('organization/updateOrganizationUser', educator.value)
      .then(() => {
        window.toast.notify.success(i18n.t('settings.educators.toast.updated'))
      })
      .catch(parseRequestError)
      .finally(() => {
        processing.value = false
      })
  }

  return {
    loading,
    processing,

    onSubmit,

    // model
    educator,

    // validation
    phoneNumberValid,
    refFormObserver,
    handlePhoneNumberValidation,
    getValidationState,
    resetForm,
    resetObserver,
  }
}
