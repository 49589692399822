<template>
  <div>

    <b-row>
      <b-col
        cols="12"
        xl="10"
        offset-xl="1"
      >

        <!-- Table Container Card -->
        <b-card
          :title="loading ? '' : educator.lastName + ' ' + educator.firstName"
          class="mb-0"
        >

          <rc-overlay
            :show="loading"
            no-fade
            variant="white"
          >

            <!-- BODY -->
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <!-- Form -->
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <!-- First Name -->
                    <validation-provider
                      #default="validationContext"
                      :name="$t('settings.educators.label.firstName')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('settings.educators.label.firstName')"
                        label-for="educator-first-name"
                      >
                        <b-form-input
                          id="educator-first-name"
                          v-model="educator.firstName"
                          trim
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <!-- Last Name -->
                    <validation-provider
                      #default="validationContext"
                      :name="$t('settings.educators.label.lastName')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('settings.educators.label.lastName')"
                        label-for="educator-last-name"
                      >
                        <b-form-input
                          id="educator-last-name"
                          v-model="educator.lastName"
                          trim
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >

                    <!-- Email -->
                    <validation-provider
                      #default="validationContext"
                      :name="$t('settings.educators.label.email')"
                      rules="required|email"
                    >
                      <b-form-group
                        :label="$t('settings.educators.label.email')"
                        label-for="educator-email"
                      >
                        <b-form-input
                          id="educator-email"
                          v-model="educator.email"
                          trim
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <!-- Phone -->
                    <validation-provider
                      #default="validationContext"
                      :name="$t('settings.educators.label.phone')"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('settings.educators.label.phone')"
                        label-for="educator-phone"
                      >
                        <vue-tel-input
                          v-model="educator.phoneNumber"
                          mode="international"
                          :dropdown-options="{ showFlags: true, showDialCodeInSelection: false, showSearchBox: true }"
                          :input-options="{ placeholder: '', showDialCode: false }"
                          :valid-characters-only="true"
                          @validate="handlePhoneNumberValidation"
                        />

                        <small
                          v-if="validationContext.errors.length"
                          class="text-danger"
                        >{{ validationContext.errors[0] }}</small>
                        <small
                          v-else-if="!phoneNumberValid"
                          class="text-danger"
                        >{{ $t('validations.messages.phone-number-invalid') }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <roles-selector v-model="educator.roles" />

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    :disabled="processing"
                  >
                    {{ educator.id ? $t('shared.btn.update') : $t('shared.btn.add') }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    :disabled="processing"
                    @click="$router.push({ name: 'organization-settings-educators-list' })"
                  >
                    {{ $t('shared.btn.cancel') }}
                  </b-button>
                </div>

              </b-form>
            </validation-observer>
          </rc-overlay>
        </b-card>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import viewSetup from '@/views/organization/settings/educators/View/viewSetup'
import RolesSelector from '@/views/organization/settings/educators/View/RolesSelector.vue'

export default {
  components: {
    RolesSelector,
    ValidationProvider,
    ValidationObserver,

    RcOverlay,
  },
  directives: {
    Ripple,
  },

  setup: viewSetup,
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
