<template>
  <div class="mt-1 mb-1">
    <h4 class="mb-75">
      {{ $t('settings.educators.label.roles') }}
    </h4>
    <b-form-radio-group
      id="radio-group-2"
      v-model="localValue"
      @change="changed"
    >
      <b-row class="match-height">

        <b-col
          v-for="role in roles"
          :key="role.role"
          cols="12"
          md="4"
          class="mb-50"
        >
          <label
            class="rounded p-1 cursor-pointer h-100"
            :class="{'border-primary': localValue === role.role, 'border': localValue !== role.role}"
          >
            <span class="d-block mb-1">
              <b-form-radio
                :id="role.role"
                :value="role.role"
                name="role"
                class="mb-0 float-left"
              />

              <span class="font-weight-bold font-medium-1">{{ role.name }}</span>

            </span>
            <span :class="{'text-primary': localValue === role.role, 'text-muted': localValue !== role.role}">{{ role.description }}</span>

          </label>
        </b-col>
      </b-row>
    </b-form-radio-group>
  </div>
</template>
<script>
import role from '@/auth/role'

export default {
  name: 'RolesSelector',
  props: {
    value: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      localValue: null,
      roles: [
        {
          role: role.educator,
          name: this.$t('settings.educators.roles.educator'),
          description: this.$t('settings.educators.roles.educator-description'),
        },
        {
          role: role.advancedEducator,
          name: this.$t('settings.educators.roles.advanced-educator'),
          description: this.$t('settings.educators.roles.advanced-educator-description'),
        },
        {
          role: role.admin,
          name: this.$t('settings.educators.roles.organization-admin'),
          description: this.$t('settings.educators.roles.organization-admin-description'),
        },
      ],
    }
  },
  watch: {
    value(newValue) {
      const value = newValue[0]
      if (value === this.localValue) {
        return
      }
      this.localValue = value
    },
  },
  methods: {
    changed(value) {
      this.$emit('input', [value])
    },
  },
}
</script>
